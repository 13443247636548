import { template as template_596fecdeafbf4e72aad8ebb7a50a26d3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_596fecdeafbf4e72aad8ebb7a50a26d3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
