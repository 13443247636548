import { template as template_1ef39fc7bffc4fb8b5200d9d5f3d0532 } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class FKControlCustom extends Component {
    static controlType = "custom";
    static{
        template_1ef39fc7bffc4fb8b5200d9d5f3d0532(`
    <div class="form-kit__control-custom">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
