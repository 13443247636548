import { template as template_3341d1b41c0e42a78759945f86b23abc } from "@ember/template-compiler";
const FKLabel = template_3341d1b41c0e42a78759945f86b23abc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
