import { template as template_89b736004d4a427bab1e9e19035f3d5a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_89b736004d4a427bab1e9e19035f3d5a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
